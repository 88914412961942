import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-165d170a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "Article" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "breadcrumbsArea" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(`${$setup.currentArticleData.title}`), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["Breadcrumb"], { breadcrumbData: $setup.breadcrumbData }, null, 8, ["breadcrumbData"])
      ]),
      _createElementVNode("div", {
        class: "htmlArea",
        innerHTML: $setup.currentArticleData.html
      }, null, 8, _hoisted_5)
    ])
  ]))
}